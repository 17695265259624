import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/layouts/faq.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Info = makeShortcode("Info");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Info mdxType="Info">Didn't find an answer? Join the <a href="https://discord.gg/NTDBRNz">community Discord</a> to get support</Info>
    <h1 {...{
      "id": "faq",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#faq",
        "aria-label": "faq permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FAQ`}</h1>
    <h3 {...{
      "id": "what-is-the-xdai-chain",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-the-xdai-chain",
        "aria-label": "what is the xdai chain permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is the xDAI Chain?`}</h3>
    <p>{`xDai is the name of a blockchain AND the stable cryptocurrency used on the chain. Each xDai token is worth ~ 1 US dollar.`}</p>
    <p>{`The xDai blockchain is a sidechain of the Ethereum blockchain. It has the same properties as Ethereum, but uses a different method to make sure transactions are valid and consistent across all nodes in the distributed network.`}</p>
    <p>{`Currently, known organizations (called validators) transmit and verify blocks every 5 seconds.`}</p>
    <p>{`This means transactions can be completed quickly and fees for transactions can be minimized. In the near future, xDai will transition to public staking where community members can also be nodes.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.xdaichain.com/"
      }}>{`Learn more`}</a></p>
    <h3 {...{
      "id": "how-do-i-add-xdai-to-my-metamask-wallet",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-add-xdai-to-my-metamask-wallet",
        "aria-label": "how do i add xdai to my metamask wallet permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I add xDAI to my Metamask wallet?`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.xdaichain.com/for-users/wallets/metamask"
      }}>{`Check this easy tutorial`}</a></p>
    <h3 {...{
      "id": "what-is-1hive-and-the-honey-token",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-1hive-and-the-honey-token",
        "aria-label": "what is 1hive and the honey token permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is 1hive and the HONEY token?`}</h3>
    <p>{`1hive is an open community, you do not need to ask permission to join or become a member. If you see something you think should be improved, you can make a proposal to get honey and make it happen.`}</p>
    <p>{`You can also earn honey simply by participating in the community on github, discord, and discourse (see Pollen), by registering and claiming honey via the BrightId faucet, or by providing liquidity on honeyswap and depositing your LP tokens into the honeycomb.`}</p>
    <p>{`While we encourage people to take initiative as individuals, we also like to work together. Self-organizing “swarms” have formed around specific projects (e.g. honeyswap, celeste, gardens), or work areas (e.g. design, communications, engineering). If you’re new to 1hive and want to contribute a good way to start is to find a swarm that interests you and ask how you might help.`}</p>
    <h3 {...{
      "id": "how-do-i-buy-hny",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-buy-hny",
        "aria-label": "how do i buy hny permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I buy HNY?`}</h3>
    <p><a parentName="p" {...{
        "href": "https://forum.1hive.org/t/1hive-101-beeginner-video-tutorials/217/2#post_3:~:text=How%20To%20Convert%20DAI%20to%20XDAI%20%26%20Swap"
      }}>{`Check this video`}</a></p>
    <h3 {...{
      "id": "whats-the-total-supply-of-hny-and-where-can-i-check-it",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#whats-the-total-supply-of-hny-and-where-can-i-check-it",
        "aria-label": "whats the total supply of hny and where can i check it permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What’s the total supply of HNY and where can I check it?`}</h3>
    <p>{`The current total supply of HNY ~24,183.2 HNY. There is an issuance rate that goes into the Common Pool (~60% per year), which is directed by Honey holders and is expected to decrease over time.`}</p>
    <p>{`You can check the total supply and the current price at `}<a parentName="p" {...{
        "href": "https://1hive.org/"
      }}>{`https://1hive.org/`}</a></p>
    <h3 {...{
      "id": "how-is-it-distributed",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-is-it-distributed",
        "aria-label": "how is it distributed permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How is it distributed?`}</h3>
    <p>{`The issuance and distribution model for Honey is simple, each block new Honey is issued and accumulates into the Common Pool, and from there it is distributed using a proposal and staking process called conviction voting.`}</p>
    <p>{`Anyone can submit a proposal requesting a portion of the Common Pool be allocated to a specific beneficiary. Honey holders can stake on the proposals to signal their support. You can access the Honey issuance and distribution dashboard at `}<a parentName="p" {...{
        "href": "https://1hive.org/"
      }}>{`https://1hive.org/`}</a>{`.`}</p>
    <h3 {...{
      "id": "how-can-i-claim-my-first-hny-from-the-faucet",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-can-i-claim-my-first-hny-from-the-faucet",
        "aria-label": "how can i claim my first hny from the faucet permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How can I claim my first HNY from the faucet?`}</h3>
    <p>{`You have to be verified with BrightID and be sponsored.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://forum.1hive.org/t/1hive-101-beeginner-video-tutorials/217#post_2:~:text=How%20To%20Get%20Verified%20%26%20Sign%20Up%20For%20Honey%20Faucet"
      }}>{`watch this video`}</a>{` for a better understanding of how to do it.`}</p>
    <p>{`After you are verified and sponsored, you can register for periods and claim some HNY. Registering for a period will let you claim HNY on the next period.`}</p>
    <p>{`Each period lasts 2 days and you’ll need to register to the faucet each period. By claiming on a period, you’ll be automatically registered for the next period. Not claiming in a period will forfeit the unclaimed amount.`}</p>
    <p>{`We have set up a bot in the #honey channel that reminds you to claim your HNY.`}</p>
    <h3 {...{
      "id": "how-do-i-get-my-liquidity-onto-the-xdai-chain",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-do-i-get-my-liquidity-onto-the-xdai-chain",
        "aria-label": "how do i get my liquidity onto the xdai chain permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I get my liquidity onto the xDai chain?`}</h3>
    <p>{`For converting Dai from mainnet to xDai on the xDai chain or vice versa use the xDai/Dai bridge: `}<a parentName="p" {...{
        "href": "https://dai-bridge.poa.network/"
      }}>{`https://dai-bridge.poa.network/`}</a></p>
    <p>{`Alternatively use this guide: `}<a parentName="p" {...{
        "href": "https://docs.tokenbridge.net/xdai-bridge/using-the-xdai-bridge/how-to-use-xdai-bridge-without-ui"
      }}>{`https://docs.tokenbridge.net/xdai-bridge/using-the-xdai-bridge/how-to-use-xdai-bridge-without-ui`}</a></p>
    <p>{`For all other ERC20 tokens (`}<strong parentName="p">{`but not Dai!`}</strong>{`) use the OmniBridge: `}<a parentName="p" {...{
        "href": "https://xdai-omnibridge.web.app/"
      }}>{`https://xdai-omnibridge.web.app/`}</a></p>
    <h3 {...{
      "id": "where-can-i-provide-liquidity-for-hny-and-earn-fees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#where-can-i-provide-liquidity-for-hny-and-earn-fees",
        "aria-label": "where can i provide liquidity for hny and earn fees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Where can I provide liquidity for HNY and earn fees?`}</h3>
    <p>{`You can go to `}<a parentName="p" {...{
        "href": "https://honeyswap.org/#/add/0x71850b7e9ee3f13ab46d67167341e4bdc905eef9/0xe91d153e0b41518a2ce8dd3d7944fa863463a97d"
      }}>{`https://honeyswap.org/#/add/0x71850b7e9ee3f13ab46d67167341e4bdc905eef9/0xe91d153e0b41518a2ce8dd3d7944fa863463a97d`}</a>{` which is currently the most liquid pair.`}</p>
    <p>{`You can check relevant APYs for all HNY pairs at `}<a parentName="p" {...{
        "href": "https://info.honeyswap.org/token/0x71850b7e9ee3f13ab46d67167341e4bdc905eef9"
      }}>{`https://info.honeyswap.org/token/0x71850b7e9ee3f13ab46d67167341e4bdc905eef9`}</a></p>
    <h3 {...{
      "id": "when-does-liquidity-mining-begin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#when-does-liquidity-mining-begin",
        "aria-label": "when does liquidity mining begin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`When does liquidity mining begin?`}</h3>
    <p>{`We don’t have a specific date yet, but expect it to start in the coming weeks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      